.tasks-details-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding: 15px;
    height: 100%;
    width: 48%;
    min-width: 300px;
    border-radius: 15px;
    border: 1px solid darkgray;
    overflow: hidden;
    overflow-y: auto;
}

.tasks-details-info-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
}

.tasks-details-title{
    display: flex;
    justify-content: space-between;
    font-family: "Passion One", sans-serif;
    font-size: 24px;
}

.tasks-details-date{
    display: flex;
    flex-flow: row nowrap;
}

.tasks-details-priority-container{
    padding: 5px;
    margin: 5px;
    height: 25px;
    border-radius: 5px;
    width: fit-content;
}

.tasks-details-priority-text{
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    margin: 0;
    vertical-align: center;
    padding: 0 5px;
    line-height: 15px;
}

.tasks-details-tags-container{
    display: flex;
    flex-flow: row wrap;
    align-self: flex-start;
    justify-self: flex-end;
}