.new-note-container{
    position: absolute;
    top: 20%;
    left: 35%;
    display: flex;
    flex-flow: column nowrap;
    background-color: whitesmoke;
    box-shadow: 3px 3px 6px slategray;
    border-radius: 20px;
    padding: 20px;
}