.nav-category-container{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
    color: black;
    font-size: 14px;
    border-radius: 5px;
    width: fit-content;
    padding: 2px 5px;
    margin: 5px 2px;
}

.nav-category-color{
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin-right: 5px;
}

.nav-category-text{
    padding-top: 3px;
    margin: 0;
    text-decoration: none !important;
}

