.new-category-container{
    width: 400px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    box-shadow: 4px 4px 6px lightgray;
    border-radius: 20px;
    position: absolute;
    top: 35%;
    left: 40%;
    padding: 20px 20px 25px 10px;
}