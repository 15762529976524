/* Provide sufficient contrast against white background */
@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap');

body{
  background-color: #C0CBA5;
  padding: 50px 50px 50px 50px;
}

.app-container{
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-flow: row nowrap;
  height: calc(100vh - 140px);
}