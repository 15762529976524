.tasks-container{
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.tasks-header{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: center;
    font-family: "Passion One", sans-serif;
    font-size: 32px;
    width: 100%;
}

.tasks-sections-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 100%;
}

.tasks-add-btn:hover{
    cursor: pointer;
}