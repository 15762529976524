.login-container{
    width: 100%;
    height: calc(100vh - 160px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.login-form{
    width: 320px;
    height: 260px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}