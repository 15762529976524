.task-line-item-container{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: 10px;
    padding: 5px;
}

.task-line-item-title{
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    margin: 0;
    vertical-align: center;
    padding: 0 5px;
}

.task-tag{
    justify-self: flex-end;
}