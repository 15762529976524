.nav-notes-container{
    display: flex;
    flex-flow: column nowrap;
}

.nav-notes-title {
    display: flex;
    flex-flow: row nowrap;
    vertical-align: center;
    height: 45px;
}

.navbar-notes-title-text{
    padding: 0;
    margin: 0 0 0 5px;
}

.nav-notes-new-category-container{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 20px;
}

.nav-notes-new-category-text{
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    font-style: italic;
    line-height: 20px;
    vertical-align: center;
    margin: 0;
}