.tag-container{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    border-radius: 5px;
    width: fit-content;
    padding: 2px 5px;
    margin: 5px 2px;
}

.tag-text{
    margin: 0;
    padding-right: 5px;
}